.home_banners_parent{
    position: relative;
    background: #1a98ff;
    padding-top: 93px;
    @media screen and (max-width: 1200px){
        padding-top: 83.8px;
    }
    @media screen and (max-width: 767px){
        padding-top: 73.78px;
    }
    @media screen and (max-width: 680px){
        padding-top: 59.03px;
    }
    @media screen and (max-width: 480px){
        padding-top: 53px;
    }
    .home_banner_image{
        height: calc(100vh - 106px);
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        position: relative;
        // opacity: .75;
        z-index: 9;
        width: 100%;
        object-fit: cover !important;
        // &::before{
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: rgb(255,255,255);
        //     background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.03125) 60%, rgba(26,152,255,1) 93%);
        //     background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.03125) 60%, rgba(26,152,255,1) 93%);
        //     background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.03125) 60%, rgba(26,152,255,1) 93%);
        //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1a98ff",GradientType=1);
        // }
        @media screen and (max-width: 768px){
            height: 550px;
        }
        @media screen and (max-width: 480px){
            height: 340px;
        }
        @media screen and (max-width: 390px){
            height: 212px;
            background-position: top !important;
        }
    }
    .videoOverlay{
        z-index: 99;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 120%;
        
        // background: rgb(255,255,255);
        // background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.03125) 60%, rgba(26,152,255,1) 93%);
        // background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.03125) 60%, rgba(26,152,255,1) 93%);
        // background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.03125) 60%, rgba(26,152,255,1) 93%);
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1a98ff",GradientType=1);
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03125) 60%, rgb(26 152 255) 82%);
        @media screen and (max-width: 768px){
            height: 120%;
            background: rgb(255, 255, 255);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03125) 65%, rgb(26, 152, 255) 82%);
        }
        @media screen and (max-width: 767px){
            height: 71%;
            background: rgb(255, 255, 255);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03125) 65%, rgb(26, 152, 255) 82%);
            margin-bottom: -71px;
            bottom: 0;
            top: unset;
        }
        @media screen and (max-width: 480px){
            height: 110%;
        }
        @media screen and (max-width: 390px){
            height: 80%;
            margin-bottom: -33px;
        }
    }
}