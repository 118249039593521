
body {
    margin: 0;
    padding: 0;
}
header{
    &.App-header {
        
        transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        .headerInner{
            background: var(--bg-black);
            padding: 18px 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 767px){
                padding: 18px 30px;
            }
            @media screen and (max-width: 680px){
                padding: 15px 20px;
            }
            @media screen and (max-width: 480px){
                padding: 15px 10px;
            }
            
        }
        
        .banner_bar{
            width: 100%;
            max-width: 100%;
            height: 10px;
            background-size: cover;
            background-position: top 56% right !important;
            background-repeat: no-repeat !important;
            @media screen and (max-width: 1200px){
                height: 6px;
            }
        }
        .navRight {
            text-align: right;
            width: 33.33%;
            position: relative;
            display: flex;
            flex-flow: wrap;
            justify-content: flex-end;
            align-items: center;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 39px;
                width: 3px;
                height: 60%;
                background: var(--bg-white);
                transform: translateY(-50%);
                @media screen and (max-width: 1200px){
                    right: 30px;
                }
                @media screen and (max-width: 680px){
                    right: 25px;
                    width: 2px;
                }
                @media screen and (max-width: 480px){
                    right: 23px;
                }
                @media screen and (max-width: 320px){
                    right: 21px;
                }
            }
            a {
                font-family: 'Amazon Ember Display Heavy';
                color: var(--text-white);
                font-size: 25px;
                position: relative;
                padding: 0 10px;
                &.active{
                    color: var(--text-blue); 
                }
                &:hover{
                    color: var(--text-blue);
                }
                @media screen and (max-width: 1200px){
                    font-size: 18px;
                }
                @media screen and (max-width: 767px){
                    font-size: 16px;
                }
                @media screen and (max-width: 680px){
                    font-size: 14px;
                }
                @media screen and (max-width: 480px){
                    font-size: 12px;
                }
                @media screen and (max-width: 320px){
                    font-size: 10px;
                }
            }
            a:last-child {
                padding-right: 0px;
            }
            @media screen and (max-width: 767px){
                width: 28%;  
            }
        }
        .navLeft {
            display: flex;
            align-items: center;
            width: 33.33%;
            @media screen and (max-width: 767px){
                width: 28%;  
            }
            .hamburger {
                width: 45px;
                cursor: pointer;
                background: transparent;
                border: 0px;
                outline: none;
                padding: 0;
                @media screen and (max-width: 1200px){
                    width: 40px;
                }
                @media screen and (max-width: 680px){
                    width: 35px;
                }
                @media screen and (max-width: 480px){
                    width: 30px;
                }
                span {
                    width: 100%;
                    height: 5px;
                    background: var(--bg-white);
                    display: block;
                    margin-bottom: 6px;
                    @media screen and (max-width: 1200px){
                        height: 5px;
                        margin-bottom: 4px;
                    }
                    @media screen and (max-width: 480px){
                        height: 3px;
                        margin-bottom: 4px;
                    }
                }
            }
            
            .primeVideoLink {
                width: 70px;
                margin-left: 35px;
                @media screen and (max-width: 1200px){
                    width: 70px;
                    margin-left: 25px;
                }
                @media screen and (max-width: 767px){
                    width: 54px;
                    margin-left: 25px;
                }
                @media screen and (max-width: 680px){
                    width: 40px;
                    margin-left: 15px;
                }
                @media screen and (max-width: 480px){
                    width: 35px;
                    margin-left: 10px;
                }
                @media screen and (max-width: 390px){
                    width: 30px;
                }
            }
        }
        .navCenter {
            width: 33.33%;
            text-align: center;
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            align-items: center;
            text-align: center;
            a {
                font-family: 'Prime Video Sharp Medium25';
                color: var(--text-white);
                font-size: 30px;
                @media screen and (max-width: 1200px){
                    font-size: 22px;
                }
                @media screen and (max-width: 991px){
                    font-size: 20px;
                }
                @media screen and (max-width: 767px){
                    font-size: 20px;
                }
                @media screen and (max-width: 680px){
                    font-size: 14px;
                }
                @media screen and (max-width: 480px){
                    font-size: 12px;
                }
                @media screen and (max-width: 320px){
                    font-size: 10px;
                }
                b {
                    font-family: "Prime Video Sharp Demi25";
                }
            }
            @media screen and (max-width: 767px){
                width: 44%;
            }
            @media screen and (max-width: 680px){
                font-size: 16px;
            }
        }
        #responsive-navbar-nav {
            position: absolute;
            top: 0;
            left: -100%;
            width: 21%;
            // height: 100%;
            background: rgb(0 0 0 / 99%);
            z-index: 9991;
            transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 15px;
            padding: 130px 30px 20px;
            @media screen and (max-width: 1200px){
                width: 220px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                padding: 100px 30px 20px;
                height: 90vh;
            }
            @media screen and (max-width: 680px){
                padding: 70px 15px 20px;
            }
            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 96px;
                background: #000;
                z-index: -1;
                @media screen and (max-width: 1200px){
                    height: 84px;
                }
                @media screen and (max-width: 767px){
                    height: 74px;
                }
                @media screen and (max-width: 680px){
                    height: 59px;
                }
                @media screen and (max-width: 480px){
                    height: 53px;
                }
            }
            .crosbtn {
                position: absolute;
                top: 25px;
                left: 25px;
                cursor: pointer;
                width: 40px;
                height: 40px;
                padding-left: 30px;
                @media screen and (max-width: 1200px){
                    width: 30px;
                    height: 30px;
                }
                @media screen and (max-width: 768px){
                    padding-left: 10px;
                }
                @media screen and (max-width: 680px){
                    top: 14px;
                    left: 25px;
                    width: 20px;
                    height: 20px;
                    padding-left: 0px;
                }
                .crosbtnInner {
                    position: relative;
                    font-size: 40px;
                    cursor: pointer;
                    width: 100%;
                    height: 100%;

                    @media screen and (max-width: 1200px){
                        font-size: 30px;
                    }
                    @media screen and (max-width: 680px){
                        font-size: 20px;
                    }
                    &::before{
                        content: "";
                        position: absolute;
                        top: 19px;
                        left: 0;
                        width: 100%;
                        height: 5px;
                        background: #fff;
                        z-index: 9;
                        transform: rotate(45deg);
                        @media screen and (max-width: 1200px){
                            top: 14px;
                            height: 4px;
                        }
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        top: 19px;
                        left: 0px;
                        width: 100%;
                        height: 5px;
                        background: #fff;
                        z-index: 9;
                        transform: rotate(-45deg);
                        @media screen and (max-width: 1200px){
                            top: 14px;
                            height: 4px;
                        }
                    }
                }
            }
            &.show {
                left: 0;
                opacity: 1;
            }
            ul {
                margin: 0;
                padding: 0;
                height: 690px;
                scrollbar-width: none;
                -ms-overflow-style: none;
                overflow-y: scroll;
                padding-left: 30px;

                &::-webkit-scrollbar {
                    width: 0px;
                    background: transparent; 
                }
                @media screen and (max-width: 1440px){
                    height: 580px;
                }
                @media screen and (max-width: 1366px){
                    height: 430px;
                }
                @media screen and (max-width: 1200px){
                    height: 80%;
                    display: flex;
                    flex-flow: wrap;
                    align-items: flex-start;
                    align-content: baseline;
                }
                @media screen and (max-width: 768px){
                    padding-left: 10px;
                }
                li {
                    list-style-type: none;
                    padding: 0;
                    margin: 0 0 40px 0;
                    text-transform: uppercase;
                    width: 100%;
                    transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                    &.active{
                        a{
                            color: var(--text-blue);
                        }
                    }
                    &:hover{
                        transform: scale(1.1);
                        transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -ms-transition: all 0.3s ease-in-out;
                    }
                    @media screen and (max-width: 1440px){
                        margin: 0 0 30px 0;
                    }
                    @media screen and (max-width: 1200px){
                        margin: 0 0 20px 0;
                    }
                    &:hover a {
                        color: var(--text-blue);
                    }
                    a {
                        color: var(--text-white);
                        font-family: 'Amazon Ember Display Heavy';
                        font-size: 30px;
                        transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -ms-transition: all 0.3s ease-in-out;
                        @media screen and (max-width: 1600px){
                            font-size: 26px;
                        }
                        @media screen and (max-width: 1440px){
                            font-size: 22px;
                        }
                        @media screen and (max-width: 1200px){
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .side{
            &.show {
                background: rgb(0 0 0 / 0%);
                bottom: 0;
                height: 100%;
                opacity: 1;
                position: fixed;
                right: 0;
                top: 0;
                transition: all .3s ease-in;
                width: 100%;
                z-index: 999;
            }
        }

    }
    
    &.is-sticky{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
    }
}

footer{
    .footerBgImg {
        background-size: cover !important;
        background-position: top 287px left 37% !important;
        padding-top: 10px;
        border-radius: 18% 72% 25% 25%/0% 19% 0% 0%;
        @media screen and (max-width: 1200px){
            padding-top: 6px;
        }
        .footerBg {
            display: flex;
            flex-flow: wrap;
            background: var(--text-black);
            border-radius: 18% 72% 25% 25% / 0% 19% 0% 0%;
            .footerTop{
                width: 100%;
                .container {
                    display: flex;
                    flex-flow: wrap;
                    padding: 150px  0% 0px;
                    width: 70%;
                    justify-content: space-between;
                    @media screen and (max-width: 1440px){
                        padding: 120px  0% 0px;    
                    }
                    @media screen and (max-width: 1200px){
                        padding: 80px  0% 0px;    
                    }
                    @media screen and (max-width: 680px){
                        padding: 50px  0% 0px;   
                        width: 85%;
                    }
                    .footerLogo {
                        width: 16%;
                    }
                    .footerLink {
                        width: 40%;
                        display: flex;
                        justify-content: space-between;
                        @media screen and (max-width: 1680px){
                            width: 45%;    
                        }
                        @media screen and (max-width: 1080px){
                            width: 50%;    
                        }
                        @media screen and (max-width: 767px){
                            width: unset;    
                        }
                        ul {
                            @media screen and (max-width: 1024px){
                                margin: 0;
                                padding: 0;  
                            }
                            li {
                                margin-bottom: 10px;
                                list-style-type: none;
                                transition: all 0.3s ease-in-out;
                                -moz-transition: all 0.3s ease-in-out;
                                -ms-transition: all 0.3s ease-in-out;
                                &:hover {
                                    transform: scale(1.1);
                                    transition: all 0.3s ease-in-out;
                                    -moz-transition: all 0.3s ease-in-out;
                                    -ms-transition: all 0.3s ease-in-out;
                                }
                                @media screen and (max-width: 1024px){
                                    margin-bottom: 2px;
                                }
                                @media screen and (max-width: 767px){
                                    margin-left: 20px;
                                    line-height: .8;
                                    margin-bottom: 10px;
                                }
                                a {
                                    font-family: 'Amazon Ember Display Regular';
                                    text-transform: uppercase;
                                    color: var(--text-white);
                                    font-size: 24px;

                                    &:hover{
                                        color: var(--text-blue);
                                    }
                                    
                                    @media screen and (max-width: 1680px){
                                        font-size: 20px;
                                    }
                                    
                                    @media screen and (max-width: 1440px){
                                        font-size: 18px;
                                    }
                                    @media screen and (max-width: 1366px){
                                        font-size: 16px;
                                    }
                                    @media screen and (max-width: 1200px){
                                        font-size: 14px;
                                    }
                                    @media screen and (max-width: 1024px){
                                        font-size: 12px;
                                    }
                                    @media screen and (max-width: 767px){
                                        font-size: 14px;
                                    }
                                    @media screen and (max-width: 480px){
                                        font-size: 12px;
                                    }
                                    @media screen and (max-width: 390px){
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .foterBar{
                width: 80%;
                max-width: 1920px;
                height: 10px;
                background-size: cover;
                margin: 80px auto;
                background-position: bottom left !important;
                @media screen and (max-width: 1440px){
                    height: 6px;
                    margin: 50px auto;
                }
                @media screen and (max-width: 1440px){
                    margin: 30px auto;
                }
                @media screen and (max-width: 680px){
                    width: 90%;
                }
                @media screen and (max-width: 390px){
                    margin: 25px auto;
                }
            }
            .footerSocial{
                width: 100%;
                .ft_social {
                    display: flex;
                    flex-flow: wrap;
                    justify-content: center;
                    max-width: 500px;
                    margin: 0 auto;
                    padding: 0;
                    li {
                        background-size: cover !important;
                        background-position: center !important;
                        margin: 0 15px;
                        border-radius: 50px;
                        list-style-type: none;
                        transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -ms-transition: all 0.3s ease-in-out;

                        &:hover{
                            transform: scale(1.2);
                            transition: all 0.3s ease-in-out;
                            -moz-transition: all 0.3s ease-in-out;
                            -ms-transition: all 0.3s ease-in-out;
                        }

                        @media screen and (max-width: 1440px){
                            margin: 0px 8px;
                        }
                        a {
                            width: 70px;
                            height: 70px;
                            display: flex;
                            justify-content: center;
                            border-radius: 50%;
                            align-items: center;
                            @media screen and (max-width: 1440px){
                                width: 50px;
                                height: 50px;
                            }
                            @media screen and (max-width: 680px){
                                width: 40px;
                                height: 40px;
                            }
                            @media screen and (max-width: 390px){
                                width: 35px;
                                height: 35px;
                            }
                            svg {
                                width: 40px;
                                height: 40px;
                                color: var(--text-white);
                                @media screen and (max-width: 1440px){
                                    width: 30px;
                                    height: 30px;
                                }
                                @media screen and (max-width: 680px){
                                    width: 24px;
                                    height: 24px;
                                }
                                @media screen and (max-width: 390px){
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                        
                        
                        
                    }
                }
            }
            .footerBottom{
                width: 100%;
                padding: 20px 0 120px;
                @media screen and (max-width: 1440px){
                    padding: 20px 0 100px;
                }
                @media screen and (max-width: 1200px){
                    padding: 20px 0 80px;
                }
                @media screen and (max-width: 767px){
                    padding: 10px 0 40px;
                }
                p {
                    color: var(--text-white);
                    text-transform: uppercase;
                    text-align: center;
                    @media screen and (max-width: 767px){
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.fr .storiesCustom h1, .fr .home_narrativesCustom h2 {
    font-size: 80px !important;
    @media screen and (max-width: 1680px){
        font-size: 65px !important;
    }
    @media screen and (max-width: 1440px){
        font-size: 60px !important;
    }
    @media screen and (max-width: 1280px){
        font-size: 50px !important;
    }
    @media screen and (max-width: 1080px){
        font-size: 40px !important;
    }
    @media screen and (max-width: 849px){
        font-size: 30px !important;
    }
    @media screen and (max-width: 767px){
        font-size: 36px !important;
    }
    @media screen and (max-width: 680px){
        font-size: 30px !important;
    }
    @media screen and (max-width: 567px){
        font-size: 22px !important;
    }
    @media screen and (max-width: 390px){
        font-size: 20px !important;
    }
    @media screen and (max-width: 360px){
        font-size: 18px !important;
    }
}

.fr .storiesCustom h5{
    
    br{
        @media screen and (max-width: 1200px){
            display: none !important;
        }

    }
}

.fr .navCenter {
    a {
        @media screen and (max-width: 680px){
            font-size: 12px !important;
        }
        @media screen and (max-width: 480px){
            font-size: 10px !important;
        }
        @media screen and (max-width: 320px){
            font-size: 8px !important;
        }
    }
}

.fr {
    footer{
        .footerBgImg {
            .footerBg {
                .footerTop{
                    .container {
                        .footerLink {
                            width: 50%;
                            @media screen and (max-width: 767px){
                                width: 80%;    
                            }
                            ul {
                                padding: 0;
                                li {
                                    @media screen and (max-width: 1024px){
                                        margin-bottom: 5px;
                                    }
                                    a {
                                        @media screen and (max-width: 1024px){
                                            line-height: 1.2;
                                            display: block;
                                        }
                                        @media screen and (max-width: 767px){
                                            font-size: 14px;
                                        }
                                        @media screen and (max-width: 480px){
                                            font-size: 12px;
                                        }
                                        @media screen and (max-width: 390px){
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


h3.coming_soon_h3{
    width: 100% !important;
    @media screen and (max-width: 1680px){
      font-size: 22px !important;
    }
    @media screen and (max-width: 1440px){
      font-size: 20px !important;
    }
    @media screen and (max-width: 1200px){
      font-size: 13px !important;
    }
}

.coming_soon_Overlay{
    opacity: 1 !important;
    display: flex !important;
  }
  @media screen and (max-width: 767px){
    .coming_soon_Overlay {
      display: flex !important;
      border-radius: 15px !important;
      height: calc(100% - 12px) !important;
      width: calc(100% - 12px) !important;
    }  
  }
  