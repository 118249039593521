.influencer{
    padding-top: 105px;
    @media screen and (max-width: 1200px){
        padding-top: 83.8px;
    }
    @media screen and (max-width: 767px){
        padding-top: 73.78px;
    }
    @media screen and (max-width: 680px){
        padding-top: 59.03px;
    }
    @media screen and (max-width: 480px){
        padding-top: 53px;
    }
    .quote{
        text-align: center;
        padding: 90px 0px 30px;
        @media screen and (max-width: 1440px){
            padding-bottom:  0px;
        }
        .container{
            width: 43%;
            margin: 0 auto;
            @media screen and (max-width: 768px){
                max-width: 340px;
                margin: 0 auto;
                width: 90%;
            }
            q  {
                font-family: 'Amazon Ember Display Heavy';
                font-size: 35px;
                text-transform: uppercase;
                line-height: .7;
                color: var(--text-white);
                margin: 0 0 20px;
                line-height: 1;
                @media screen and (max-width: 1680px){
                    font-size: 28px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 24px;
                }
                @media screen and (max-width: 1080px){
                    font-size: 18px;
                    line-height: .7;
                }
                @media screen and (max-width: 991px){
                    font-size: 16px;
                }
                br{
                    @media screen and (max-width: 540px){
                        display: none;
                    }

                }
                &::after{
                    font-size: 63px;
                    position: absolute;
                    bottom: -20px;
                    @media screen and (max-width: 1680px){
                        bottom: -18px;
                        font-size: 52px;
                    }
                    @media screen and (max-width: 1440px){
                        font-size: 45px;
                        bottom: -14px;
                    }
                    @media screen and (max-width: 1200px){
                        font-size: 40px;
                        bottom: -9px;
                    }
                    @media screen and (max-width: 767px){
                        font-size: 30px;
                        bottom: 0px;
                    }
                }
                &::before{
                    font-size: 63px;
                    line-height: .5;
                    @media screen and (max-width: 1680px){
                        font-size: 52px;
                    }
                    @media screen and (max-width: 1440px){
                        font-size: 45px;
                    }
                    @media screen and (max-width: 1200px){
                        font-size: 40px;
                    }
                    @media screen and (max-width: 767px){
                        font-size: 30px;
                    }
                }
            }
        }
    }
}