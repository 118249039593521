.In_bio{
    text-align: center;
    
    .container{
        max-width: 1470px;
        margin: 0 auto;
        width: 100%;
        @media screen and (max-width: 1680px){
            max-width: 70%;
        }
        @media screen and (max-width: 767px){
            max-width: 576px;
            padding: 0 20px;
        }
        @media screen and (max-width: 640px){
            max-width: 90%;
            padding: 0px;
        }
            
        .bioinner{

            h1{
                font-family: 'Prime Video Sharp Demi25';
                color: var(--text-white);
                font-size: 90px;
                line-height: .95;
                text-transform: uppercase;
                margin-bottom: 35px;
                
                @media screen and (max-width: 1680px){
                    font-size: 70px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 60px;
                }
                @media screen and (max-width: 1366px){
                    margin-bottom: 20px;
                } 
                @media screen and (max-width: 1080px){
                    font-size: 50px;
                }
                @media screen and (max-width: 991px){
                    font-size: 40px;
                }    
                @media screen and (max-width: 814px){
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 767px){
                    font-size: 45px;
                    margin-top: 0;
                }
                @media screen and (max-width: 680px){
                    font-size: 45px;
                }
                @media screen and (max-width: 567px){
                    font-size: 40px;
                }
                @media screen and (max-width: 390px){
                    font-size: 35px;
                }
                @media screen and (max-width: 360px){
                    font-size: 22px;
                }
                span{
                    color: var(--text-white);
                    font-family: 'Prime Video Sharp Medium25';
                    font-style: italic;
                    display: block;
                }

            }
            h5{
                font-family: 'Amazon Ember Display Heavy';
                font-size: 35px;
                text-transform: uppercase;
                line-height: .95;
                color: var(--text-black);
                margin: 0 0 20px;
                @media screen and (max-width: 1680px){
                    font-size: 28px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 24px;
                }    
                @media screen and (max-width: 1080px){
                    font-size: 18px;
                }
                @media screen and (max-width: 991px){
                    font-size: 16px;
                }
                br{
                    @media screen and (max-width: 540px){
                        display: none;
                    }

                }

                a{
                    
                    color: var(--text-black);
                    &:hover{
                        color: var(--text-white);
                    }
                }
            }
            p{
                font-family: 'Amazon Ember Display Regular';
                font-size: 28px;
                line-height: 1.2;
                color: var(--text-white);
                @media screen and (max-width: 1680px){
                    font-size: 24px;
                }
                @media screen and (max-width: 1600px){
                    padding-left: 3%;
                    padding-right: 3%;
                }
                @media screen and (max-width: 1600px){
                    padding-left: 0%;
                    padding-right: 0%;
                }
                @media screen and (max-width: 1440px){
                    font-size: 20px;
                }
                @media screen and (max-width: 1080px){
                    font-size: 18px;
                }
                @media screen and (max-width: 991px){
                    font-size: 16px;
                }
                strong{
                    font-family: 'Amazon Ember Display Heavy';
                }
            }
        }
    }
}