.behindscene{
    padding: 0px 0;
    // @media screen and (max-width: 1366px){
    //     padding: 60px 0;
    // }
    // @media screen and (max-width: 640px){
    //     padding: 50px auto;
    // }
    .container{
        max-width: 1470px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 1680px){
            max-width: 70%;
        }
        @media screen and (max-width: 1160px){
            max-width: 75%;
        }
        @media screen and (max-width: 814px){
            flex-flow: column-reverse;
            max-width: 70%;
        }
        @media screen and (max-width: 767px){
            max-width: 576px;
            padding: 0 20px;
        }
        @media screen and (max-width: 640px){
            max-width: 90%;
            padding: 0px;
        }
        .behindsceneImg{
            width: 580px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .arrow {
                margin: 20px;
                width: 40px;
                height: 40px;
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                z-index: 9;
                cursor: pointer;
                filter: invert(1);
                @media screen and (max-width: 414px){
                    width: 25px;
                    height: 25px;
                    
                }
                &:first-child{
                    left: -10%;
                    padding: 0;
                    margin-left: 0;
                    @media screen and (max-width: 1200px){
                        left: -20%;
                    }
                    @media screen and (max-width: 814px){
                        left: -2%;
                    }
                    @media screen and (max-width: 640px){
                        left: 0;
                    }
                }
                &:last-child{
                    right: -13%;
                    margin-right: 0;
                    padding: 0;
                    @media screen and (max-width: 1200px){
                        background-position: center;
                        right: -20%;
                    }
                    @media screen and (max-width: 814px){
                        right: -4%;
                    }
                    @media screen and (max-width: 640px){
                        background-position: center;
                        right: -2.5%;
                    }
                    @media screen and (max-width: 414px){
                        right: -2%;
                    }
                }
                &:hover{
                    filter: invert(0);
                }
            }
            
            @media screen and (max-width: 1680px){
                width: 480px;
            }
            @media screen and (max-width: 1440px){
                width: 430px;
            }
            @media screen and (max-width: 1200px){
                width: 300px;
            }
            @media screen and (max-width: 814px){
                width: 100%;
            }
        }
        .behindsceneText{
            width: 48%;
            text-align: left;
            @media screen and (max-width: 1200px){
                width: 52%;
            }
            @media screen and (max-width: 1080px){
                width: 50%;
            }
            @media screen and (max-width: 991px){
                width: 48%;
            }
            @media screen and (max-width: 814px){
                width: 100%;
                text-align: center;
            }
            h2{
                font-family: 'Prime Video Sharp Demi25';
                color: var(--text-white);
                font-size: 90px;
                line-height: .95;
                text-transform: uppercase;
                margin-bottom: 35px;
                margin-top: 0;
                margin: 0 !important;
                
                @media screen and (max-width: 1680px){
                    font-size: 70px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 60px;
                }    
                @media screen and (max-width: 1366px){
                    margin-bottom: 20px;
                } 
                @media screen and (max-width: 1280px){
                    font-size: 50px;
                }
                @media screen and (max-width: 1080px){
                    font-size: 50px;
                }
                @media screen and (max-width: 991px){
                    font-size: 40px;
                }
                @media screen and (max-width: 814px){
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 767px){
                    font-size: 55px;
                    margin-top: 0;
                }
                @media screen and (max-width: 680px){
                    font-size: 45px;
                }
                @media screen and (max-width: 567px){
                    font-size: 40px;
                }
                @media screen and (max-width: 390px){
                    font-size: 35px;
                }
                @media screen and (max-width: 360px){
                    font-size: 22px;
                }
                span{
                    color: var(--text-black);
                    display: block;
                }

            }
            p{
                font-family: 'Amazon Ember Display Regular';
                font-size: 28px;
                line-height: 1.2;
                color: var(--text-white);
                @media screen and (max-width: 1680px){
                    font-size: 24px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 20px;
                }
                @media screen and (max-width: 1080px){
                    font-size: 18px;
                }
                @media screen and (max-width: 991px){
                    font-size: 16px;
                }
                strong{
                    font-family: 'Amazon Ember Display Heavy';
                }
            }
        }
    }
}
