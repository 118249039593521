
.video_container {
    position: relative;
    width: 550px;
    height: 550px;
    margin: 80px auto;
    border-radius: 20px;
    overflow: hidden;
    border: 10px solid #fff;
    @media screen and (max-width: 1680px){
        width: 450px;
        height: 450px;
    }
    @media screen and (max-width: 1440px){
        width: 400px;
        height: 400px;
    }
    @media screen and (max-width: 1366px){
        margin: 60px auto;
        border: 8px solid #fff;
    }
    @media screen and (max-width: 1200px){
        width: 300px;
        height: 300px;
    }
    @media screen and (max-width: 814px){
        width: 300px;
        height: 300px;
        margin: 50px auto 30px;
    }
    @media screen and (max-width: 640px){
        width: 300px;
        height: 300px;
        
        border: 6px solid #fff;
    }
    @media screen and (max-width: 414px){
        width: 220px;
        height: 220px;
    }
    // padding-bottom: 56.25%; /* Maintain a 16:9 aspect ratio */
    .bgBlack {
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .playbutton {        
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        height: 80px;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        &:hover{
            transform: scale(1.2);
            transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
        }
        
        @media screen and (max-width: 1200px){
            height: 65px;
        }
        @media screen and (max-width: 414px){
            height: 45px;
        }
    }
    
  }
  
  
  
  
  
  