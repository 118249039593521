.home_narratives_parent{
    text-align: center;
    position: relative;
    padding-bottom: 35px;
    
    .container{
        max-width: 1470px;
        margin: 0 auto;
        width: 100%;
        @media screen and (max-width: 1680px){
            max-width: 75%;
        }
        @media screen and (max-width: 767px){
            max-width: 576px;
            padding: 0 20px;
        }
        @media screen and (max-width: 640px){
            max-width: 90%;
            padding: 0px;
        }
        .narratives_inner{
            padding: 0 7%;
            @media screen and (max-width: 1600px){
                padding: 0%;
            }
            h2{
                font-family: 'Prime Video Sharp Demi25';
                color: var(--text-white);
                font-size: 90px;
                line-height: .95;
                text-transform: uppercase;
                margin-bottom: 35px;
                @media screen and (max-width: 1680px){
                    font-size: 80px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 70px;
                }
                @media screen and (max-width: 1080px){
                    font-size: 50px;
                }
                @media screen and (max-width: 814px){
                    font-size: 40px;
                }
                @media screen and (max-width: 767px){
                    font-size: 55px;
                    margin-top: 0;
                }
                @media screen and (max-width: 680px){
                    font-size: 45px;
                }
                @media screen and (max-width: 567px){
                    font-size: 40px;
                    margin: 0 auto 30px; 
                }
                @media screen and (max-width: 390px){
                    font-size: 35px;
                }
                @media screen and (max-width: 360px){
                    font-size: 22px;
                }
                span{
                    color: var(--text-black);
                    @media screen and (max-width: 768px){
                        max-width: 70%;
                        margin: 0 auto;
                        display: block;
                        
                    }
                    @media screen and (max-width: 500px){
                        max-width: 90%;
                        
                    } 
                    br {
                        display: none;
                        @media screen and (max-width: 768px){
                            display: block;
                        }
                    }
                }

            }
            h5{
                font-family: 'Amazon Ember Display Heavy';
                font-size: 35px;
                text-transform: uppercase;
                line-height: .95;
                color: var(--text-white);
                margin: 0 0 20px;
                @media screen and (max-width: 1680px){
                    font-size: 28px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 24px;
                }
                @media screen and (max-width: 1080px){
                    font-size: 18px;
                }
                @media screen and (max-width: 991px){
                    font-size: 16px;
                }
                @media screen and (max-width: 768px){
                    font-size: 20px;
                }
                br{
                    display: none;
                    @media screen and (max-width: 768px){
                        display: block;
                    }
                }
            }
            p{
                font-family: 'Amazon Ember Display Regular';
                font-size: 28px;
                line-height: 1.2;
                color: var(--text-white);

                @media screen and (max-width: 1680px){
                    font-size: 24px;
                }
                @media screen and (max-width: 1600px){
                    padding-left: 3%;
                    padding-right: 3%;
                }
                @media screen and (max-width: 1600px){
                    padding-left: 0%;
                    padding-right: 0%;
                }
                @media screen and (max-width: 1440px){
                    font-size: 20px;
                }
                @media screen and (max-width: 1080px){
                    font-size: 18px;
                }
                @media screen and (max-width: 991px){
                    font-size: 16px;
                    margin-bottom: 0;
                }
                strong{
                    font-family: 'Amazon Ember Display Heavy';
                }
                br{
                    @media screen and (max-width: 767px){
                        display: none;
                    }
                }
            }
        }
        .narratives_feature{
            display: flex;
            flex-flow: wrap;
            justify-content: space-around;
            max-width: 90%;
            margin: 0 auto;
            @media screen and (max-width: 1600px){
                max-width: 100%;
            }
            .narratives_feature_box{
                width: 41%;
                
                @media screen and (max-width: 1600px){
                    width: 43%;
                }
                @media screen and (max-width: 1440px){
                    width: 38%;
                }
                @media screen and (max-width: 1440px){
                    width: 41%;
                }
                @media screen and (max-width: 1200px){
                    width: 43%;
                }
                @media screen and (max-width: 1024px){
                    width: 47%;
                }
                @media screen and (max-width: 767px){
                    width: 100%;
                }
                img{
                    width: 34%;
                    object-fit: contain;
                    margin: 35px 0 40px;
                    @media screen and (max-width: 767px){
                        height: 110px;
                        margin: 40px 0 35px;
                    }
                }
                p{
                    font-family: 'Amazon Ember Display Regular';
                    font-size: 28px;
                    line-height: 1.2;
                    color: var(--text-white);
                    margin: 0;
    
                    @media screen and (max-width: 1680px){
                        font-size: 24px;
                    }
                    @media screen and (max-width: 1440px){
                        font-size: 20px;
                    }
                    @media screen and (max-width: 1080px){
                        font-size: 18px;
                    }
                    @media screen and (max-width: 991px){
                        font-size: 16px;
                    }
                    strong{
                        font-family: 'Amazon Ember Display Heavy';
                    }
                    @media screen and (max-width: 414px){
                        max-width: 240px;
                        margin: 0 auto;
                    }
                }
                a {
                    img{
                        
                        transition: all 0.5s ease-in-out;
                        -moz-transition: all 0.5s ease-in-out;
                        -ms-transition: all 0.5s ease-in-out;
                    }
                    &:hover img{
                        transform: scale3d(1.1, 1.1, 1);
                        transition: all 0.5s ease-in-out;
                        -moz-transition: all 0.5s ease-in-out;
                        -ms-transition: all 0.5s ease-in-out;
                    }
                }
            }
        }
    }
}