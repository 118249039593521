.flickpick{
    padding: 80px 0;
    @media screen and (max-width: 1366px){
        padding: 60px 0;
    }
    @media screen and (max-width: 814px){
        padding: 50px 0 0;
    }
    .container{
        max-width: 1470px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 1680px){
            max-width: 70%;
        }
        @media screen and (max-width: 1160px){
            max-width: 75%;
        }
        @media screen and (max-width: 814px){
            max-width: 70%;
        }
        @media screen and (max-width: 767px){
            max-width: 576px;
            padding: 0 20px;
        }
        @media screen and (max-width: 640px){
            max-width: 90%;
            padding: 0px;
        }
        .flickImg{
            width: 48%;
            @media screen and (max-width: 1200px){
                width: 51%;
            }
            @media screen and (max-width: 814px){
                width: 100%;
            }
            .flickImgInner{
                width: 550px;
                height: 550px;
                border-radius: 20px;
                overflow: hidden;
                border: 10px solid #fff;
                @media screen and (max-width: 1680px){
                    width: 450px;
                    height: 450px;
                }
                @media screen and (max-width: 1440px){
                    width: 400px;
                    height: 400px;
                }
                @media screen and (max-width: 1366px){
                    border: 8px solid #fff;
                }
                @media screen and (max-width: 1200px){
                    width: 300px;
                    height: 300px;
                }
                @media screen and (max-width: 414px){
                    width: 220px;
                    height: 220px;
                }
                @media screen and (max-width: 814px){
                    margin: 0 auto;
                }
                @media screen and (max-width: 640px){
                    border: 6px solid #fff;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.5s ease-in-out;
                    -moz-transition: all 0.5s ease-in-out;
                    -ms-transition: all 0.5s ease-in-out;
                }
                // &:hover{
                //     img{
                //         -webkit-transform: scale3d(1.2, 1.2, 1);
                //         transform: scale3d(1.2, 1.2, 1);
                //         transition: all 0.5s ease-in-out;
                //         -moz-transition: all 0.5s ease-in-out;
                //         -ms-transition: all 0.5s ease-in-out;
                        
                //     }
                // }

            }
        }
        .flickText{
            width: 48%;
            text-align: right;

            @media screen and (max-width: 1680px){
                width: 43%;
            }
            @media screen and (max-width: 814px){
                width: 100%;
                margin-top: 30px;
                text-align: center;
            }
            h2{
                font-family: 'Prime Video Sharp Demi25';
                color: var(--text-white);
                font-size: 90px;
                line-height: .95;
                text-transform: uppercase;
                margin-bottom: 35px;
                margin-top: 0;
                
                @media screen and (max-width: 1680px){
                    font-size: 70px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 60px;
                }    
                @media screen and (max-width: 1366px){
                    margin-bottom: 20px;
                } 
                @media screen and (max-width: 1080px){
                    font-size: 50px;
                }
                @media screen and (max-width: 991px){
                    font-size: 40px;
                }    
                @media screen and (max-width: 814px){
                    margin-bottom: 20px;
                } 
                @media screen and (max-width: 767px){
                    font-size: 55px;
                    margin-top: 0;
                }
                @media screen and (max-width: 680px){
                    font-size: 45px;
                }
                @media screen and (max-width: 567px){
                    font-size: 40px;
                }
                @media screen and (max-width: 390px){
                    font-size: 35px;
                }
                @media screen and (max-width: 360px){
                    font-size: 22px;
                }
                span{
                    color: var(--text-black);
                    display: block;
                }

            }
            p{
                font-family: 'Amazon Ember Display Regular';
                font-size: 28px;
                line-height: 1.2;
                color: var(--text-white);
                @media screen and (max-width: 1680px){
                    font-size: 24px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 20px;
                }
                @media screen and (max-width: 1080px){
                    font-size: 18px;
                }
                @media screen and (max-width: 991px){
                    font-size: 16px;
                }
                strong{
                    font-family: 'Amazon Ember Display Heavy';
                }
            }
            .watchBtn{
                font-family: 'Amazon Ember Display Bold';
                font-size: 35px;
                color: var(--text-black);
                background: var(--text-white);
                border-radius: 20px;
                padding: 15px 20px;
                margin-top: 10px;
                display: inline-block;
                border: 6px solid #1a98ff;
                transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                @media screen and (max-width: 1440px){
                    font-size: 28px;
                }
                @media screen and (max-width: 1280px){
                    font-size: 22px;
                }
                @media screen and (max-width: 814px){
                    font-size: 18px;
                    border-radius: 16px;
                    padding: 11px 16px;
                }
                &:hover{
                    color: var(--text-white);
                    background: var(--bg-black);
                    border: 6px solid var(--text-white);
                    transform: scale(1.1);
                    transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}