
:root {
  --bg-blue-color: #1a98ff;
  --text-white: #ffffff;
  --text-black: #000000;
  --text-blue: #1a98ff;
  --bg-black: #000000;
  --bg-white: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-blue-color);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a{
  text-decoration: none;
}

/* Amazon Fonts */

@font-face {
  font-family: 'Amazon Ember Display Light';
  src: url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Light.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Amazon Ember Display Bold';
  src: url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Bold.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Amazon Ember Display Medium';
  src: url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Medium.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Amazon Ember Display Heavy';
  src: url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Heavy.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Amazon Ember Display Regular';
  src: url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Regular.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Amazon/AmazonEmberDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* Prime Fonts */

@font-face {
  font-family: 'Prime Video Sharp Demi25';
  src: url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-Demi25.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-Demi25.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prime Video Sharp DemiItl25';
  src: url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-DemiItl25.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-DemiItl25.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prime Video Sharp Medium25';
  src: url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-Medium25.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-Medium25.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prime Video Sharp MediumItl25';
  src: url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-MediumItl25.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-MediumItl25.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prime Video Sharp Medium20';
  src: url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-Medium20.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-Medium20.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prime Video Sharp MediumItl20';
  src: url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-MediumItl20.woff2') format('woff2'),
      url('./Components/Utils/Partials/Assets/Fonts/Prime/PrimeVideoSharp-MediumItl20.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}


.container {
  max-width: 1470px;
  margin: 0 auto;
  width: 100%;
}

/*  homepage slider start */
.slick-slide>div {
  /* padding: 40px; */
  padding: 0;
  width: 90%;
  margin: 0 auto;
}
.slick-prev {
  width: 70px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 70px !important;
}
.slick-next {
  width: 70px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 70px !important;
}
.slick-prev:before, .slick-next:before {
  display: none;
}
.slick-prev {
  left: -0 !important;
}
.slick-next {
  right: -0px !important;
}
.slick-slider {
  padding: 0 20%;
}
.slick-slide>div:last-child {
  padding-top: 15px;
}
.slick-slide>div:first-child {
  padding-bottom: 15px;
}
.slick-prev:hover, .slick-next:hover {
  filter: invert(1);
}
@media screen and (max-width: 1680px){ 
  .slick-slide>div {
    padding: 0;
    width: 90%;
    margin: 0 auto;
  }

}
@media screen and (max-width: 1600px){ 

  .slick-prev, .slick-next {
    width: 60px !important;
    height: 60px !important;
  }
}
@media screen and (max-width: 768px){
  .slick-slide>div {
      padding: 15px;
  }
  .slick-next, .slick-prev {
      width: 40px !important;
      height: 40px !important;
  }
  .slick-next {
      right: -35px !important;
  }
  .slick-prev {
      left: -35px !important;
  }
}
@media screen and (max-width: 767px){
  .slick-slider {
    padding: 0 5%;
  }
}
@media screen and (max-width: 576px){
  .slick-next {
    right: -30px !important;
}
  .slick-next, .slick-prev {
      width: 30px !important;
      height: 30px !important;
  }
  .slick-prev {
      left: -30px !important;
  }
}
/*  homepage slider end */

@media screen and (max-width: 1680px){
  .container {
    max-width: 85%;
    margin: 0 auto;
  }  
}

/* SINGLE INFLUENCER PAGE */
/* Banner video */

.video-js{
  width: 100% !important;
  height: 100% !important;
}

/* .primeVideoLink{
  margin-left: 0px !important;
} */

.App.fr q::after, .App.fr q::before{
  display: none !important;
}