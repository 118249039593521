.home_slider_parent{
    
    margin: auto;
    padding: 50px 0 30px;
    @media screen and (max-width: 768px){
      padding: 20px 0 15px;
    }
    .container{
      max-width: 1470px;
      margin: 0 auto;
      width: 100%;
      @media screen and (max-width: 1680px){
        max-width: 75%;
      }
      .slide {
        position: relative;
        a{
          border-radius: 20px;
          overflow: hidden;
          border: 10px solid #fff;
          display: block;
          @media screen and (max-width: 1200px){
            border: 8px solid #fff;
          }
          @media screen and (max-width: 768px){
            border: 6px solid #fff;
          }
          img{
            width: 100%;
            transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
          }
          &:hover{
              img{
                  -webkit-transform: scale3d(1.2, 1.2, 1);
                  transform: scale3d(1.2, 1.2, 1);
                  transition: all 0.5s ease-in-out;
                  -moz-transition: all 0.5s ease-in-out;
                  -ms-transition: all 0.5s ease-in-out;
                  
              }
          }
          .overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.3s ease;
            border-radius: 10px;
            transform: translate(-50%, -50%);
            overflow: hidden;
            h3 {
              color: var(--text-white); // Adjust the overlay text color
              font-size: 30px; // Adjust the overlay text size
              text-transform: uppercase;
              font-family: 'Prime Video Sharp Demi25';
              width: 80%;
              text-align: center;
              margin: 0 auto;
              line-height: .95;
              @media screen and (max-width: 1680px){
                font-size: 26px;
              }
              @media screen and (max-width: 1440px){
                font-size: 22px;
              }
              @media screen and (max-width: 1200px){
                font-size: 16px;
              }
            }
            @media screen and (max-width: 767px){
              display: none;
            }
          }
          &:hover {
              .overlay {
                opacity: 1;
              }
          }
          
        }
        
      }

    }
}


  
