.headerbar{
    width: 100%;
    max-width: 100%;
    height: 10px;
    background-size: cover;
    background-position: top 56% right !important;
    background-repeat: no-repeat !important;
    
    @media screen and (max-width: 1200px){
        height: 6px;
    }
}