.influencer_pride_parent{
    text-align: center;
    position: relative;
    padding: 60px 0 130px;
    @media screen and (max-width: 1280px){
        padding: 40px 0 80px;
    }
    @media screen and (max-width: 1080px){
        padding: 10px 0 60px;
    }
    .container{
        max-width: 1470px;
        margin: 0 auto;
        width: 100%;
        @media screen and (max-width: 1680px){
            max-width: 75%;
        }
        .influencer_pride_inner{
            padding-top: 70px;
            p{
                font-family: 'Amazon Ember Display Bold';
                font-size: 22px;
                line-height: 1.2;
                color: var(--text-white);
                margin-bottom: 10px;

                
                @media screen and (max-width: 1680px){
                    font-size: 18px;
                }
                @media screen and (max-width: 1440px){
                    font-size: 16px;
                }
                @media screen and (max-width: 1280px){
                    font-size: 14px;
                }
                @media screen and (max-width: 991px){
                    font-size: 12px;
                    margin-bottom: 10px;
                }
                @media screen and (max-width: 680px){
                    font-size: 12px;
                }
            }
            img{
                width: 22%;
                @media screen and (max-width: 991px){
                    width: 170px;
                }   
                // @media screen and (max-width: 767px){
                //     width: 32%;
                // }    
                // @media screen and (max-width: 680px){
                //     width: 137px;
                // }    
            }
            
           
        }
    }
}